import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import IconButtons from "../components/Button/IconButtons"

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
    maxWidth: "859px",
    "@media (max-width: 900px)": {
      padding: "16px",
      maxWidth: "750px"
    }
  },
  heading: {
    paddingTop: "114px",
    "@media (max-width: 800px)": {
      height: "114px",
      background: "#1CB0BA",
      paddingTop: "0px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      width: "100vw",
      marginLeft: "calc(-1 * ((100vw - 100%) / 2))"
    },
    "& h1": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "48px",
      lineHeight: "45px",
      color: "#17124e",
      padding: "0px",
      "@media (max-width: 800px)": {
        fontSize: "20px",
        lineHeight: "36px"
      }
    }
  },
  container: {
    paddingTop: "80px",
    "@media (max-width: 800px)": {
      paddingTop: "70px"
    },
    "& .MuiTextField-root": {
      height: "100%",
      width: "100%"
      // maxWidth: "310px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(0px, -24px) scale(1)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#000000"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        "& legend": {
          display: "none"
        }
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#17124E",
      borderWidth: "1px"
    }
  },
  firstRow: {
    display: "flex",
    flexDrection: "row",
    width: "100%",
    "& $left": {
      paddingRight: "16px",
      "@media (max-width: 800px)": {
        paddingRight: "16px"
      }
    }
  },
  left: {
    flex: 1,
    height: "62px"
  },
  right: {
    flex: 1,
    height: "62px",
    textAlign: "right"
  },
  SecondRow: {
    display: "flex",
    flexDrection: "row",
    width: "100%",
    paddingTop: "43px",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      "& .MuiTextField-root ": {
        maxWidth: "unset"
      }
    },
    "& $left": {
      paddingRight: "16px",
      "@media (max-width: 800px)": {
        paddingRight: "0px"
      }
    },
    "& $right": {
      "@media (max-width: 800px)": {
        marginTop: "45px"
      }
    }
  },

  TextAreaRow: {
    paddingTop: "57px",
    paddingBottom: "26px",
    "& .MuiTextField-root": {
      // maxWidth: "638px",
    },
    "& .MuiInputBase-input ": {
      // maxWidth: "638px",
      height: "238px"
    }
  },
  submit: {
    fontSize: "20px",
    margin: "17px 0px 64px",
    "@media (max-width: 800px)": {
      margin: "17px auto 64px"
    }
  }
}))
export default function Makeaclaim({ data }) {
  const {
    datoCmsClaimPage: { field, header, buttonText }
  } = data

  const classes = useStyles()
  const init = {}

  const inputHandler = (e, field) => {
    let myState = { ...state }

    myState[field] = e.target.value
    setState(myState)
  }

  const [state, setState] = useState(init)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": form.getAttribute("name"), ...state })
    })
      .then(data => {
        if (data.status === 200) {
          navigate("/success")
        }
      })
      .catch(error => alert(error))
  }

  return (
    <Layout pageTitle={"Service Request"}>
      <div className={classes.root}>
        <div className={classes.heading}>
          <h1>{header}</h1>
        </div>
        <form
          className={classes.container}
          data-netlify="true"
          onSubmit={e => handleSubmit(e)}
          name="claim"
          data-netlify-honeypot="bot-field"
          method="post"
        >
          <div className={classes.firstRow}>
            <input type="hidden" name="form-name" value="claim" />
            <div className={classes.left}>
              <TextField
                id={field[0].fieldId}
                label={field[0].label}
                name={field[0].fieldId}
                variant="outlined"
                required
                multiline={field.multiline}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => inputHandler(e, field[0].fieldId)}
              />
            </div>
            <div className={classes.right}>
              <TextField
                id={field[1].fieldId}
                label={field[1].label}
                multiline={field.multiline}
                name={field[0].fieldId}
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => inputHandler(e, field[1].fieldId)}
              />
            </div>
          </div>
          <div className={classes.SecondRow}>
            {field
              .slice(2, field.length)
              .filter(item => !item.fullWidth)
              .map((field, index) => {
                return (
                  <div
                    className={index % 2 === 0 ? classes.left : classes.right}
                    key={index}
                  >
                    <TextField
                      id={field.fieldId}
                      label={field.label}
                      multiline={field.multiline}
                      name={field.fieldId}
                      type={field.label === "Email" ? "email" : ""}
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={e => inputHandler(e, field.fieldId)}
                    />
                  </div>
                )
              })}
          </div>
          <div className={classes.TextAreaRow}>
            {field
              .slice(2, field.length)
              .filter(item => item.fullWidth)
              .map((field, index) => {
                return (
                  <TextField
                    key={index}
                    id={field.fieldId}
                    label={field.label}
                    multiline={field.multiline}
                    name={field.fieldId}
                    variant="outlined"
                    rows={3}
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={e => inputHandler(e, field.fieldId)}
                  />
                )
              })}
          </div>
          <div className={classes.submit}>
            <IconButtons type="submit">{buttonText}</IconButtons>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query claimPage {
    datoCmsClaimPage {
      header
      field {
        label
        fieldId
        multiline
        fullWidth
      }
      buttonText
    }
  }
`
